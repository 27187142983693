<template>
  <v-dialog
    v-model="isOpen"
    max-width="400"
    persistent
  >
    <v-card>
      <v-card-title class="subheading">
        Biometric File
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-layout row class="pb-3">
            <v-flex>
              <v-layout
              row
              v-for="(file, key) in documents"
              :key="key"
              >
                <v-flex>
                  <v-subheader>
                    <audio type="audio/x-wav" controls>
                      <source :src="generateUrl(file)" />
                    </audio>
                </v-subheader>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-divider />

          <v-layout row v-if="showEnrollment" class="pt-4">
            <v-flex>
              <v-select
                label="Select Enrollment"
                :items="enrollments"
                v-model="enrollments[0]"
                item-text="created"
                item-value="guid"
                return-object
                @change="changeEnrollment"
              />
            </v-flex>
          </v-layout>

          <v-layout row v-if="showEnrollment">
            <v-flex>
              <v-layout
                row
                v-for="(voiceFile, vKey) in selectedEnrollment.files"
                :key="vKey"
              >
                <v-flex>
                  <v-subheader>
                    <audio type="audio/x-wav" controls>
                      <source :src="generateUrl(voiceFile)" />
                    </audio>
                </v-subheader>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <BaseButton
          :color="getColor"
          :disabled="disableEnrollment"
          text="View Enrollment"
          @clicked="showEnrollment = !showEnrollment"
        />
        <BaseButton @clicked="closed" color="accent" text="Close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ues from '@/services/UserEnrollmentService';

export default {
  name: 'BiometricVoiceDialog',
  props: {
    open: Boolean,
    enrollments: Array,
    documents: Array,
    authToken: String,
    disableEnrollment: Boolean,
  },
  data: () => ({
    isOpen: false,
    selectedEnrollment: null,
    showEnrollment: false,
  }),
  computed: {
    getColor() {
      return !this.showEnrollment ? 'accent' : '';
    },
  },
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  watch: {
    open(val) {
      this.isOpen = val;
    },
    enrollments(enrollments) {
      if (enrollments.length > 0) {
        this.setSelectedEnrollment(this.enrollments[0]);
      }
    },
    disableEnrollment(disableEnrollment) {
      if (disableEnrollment) {
        this.showEnrollment = true;
      } else {
        this.showEnrollment = false;
      }
    },
  },
  methods: {
    closed() {
      this.isOpen = false;
      this.showEnrollment = false;
      this.$emit('closed');
    },
    changeEnrollment(file) {
      this.setSelectedEnrollment(file);
    },
    setSelectedEnrollment(file) {
      this.selectedEnrollment = file;
    },
    generateUrl(file) {
      if (this.authToken) {
        return ues.getGuidSrc(file.guid, this.authToken);
      }
      return '';
    },
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
    }
    if (this.enrollments.length > 0) {
      this.setSelectedEnrollment(this.enrollments[0]);
    }
    if (this.disableEnrollment) {
      this.showEnrollment = true;
    } else {
      this.showEnrollment = false;
    }
  },
};
</script>
